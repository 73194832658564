import React from "react";
import { Link } from "react-router-dom";
import "./Services.css";
import { motion } from "framer-motion";
import webDev from "../../images/webdev.png";
import AppDev from "../../images/appdev.png";
import DeskTopApp from "../../images/desktdev.png";
import UXDes from "../../images/uxdes.png";
import GraphicBrand from "../../images/graphicdes.png";
import BrandingServ from "../../images/brandguid.png";
import WhyChooseUs from "../../component/WhyChooseUs/WhyChooseUs";
import Review from "../../component/Review/Review";
import { FormTitle } from "../../component/FormTitle/FomTitle";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { type: "spring", delay: 0.5 } },
  exit: { x: "-100vw", transition: { ease: "easeInOut" } },
};

const Services = () => {
  const servicesData = [
    {
      img: webDev,
      title: "Web Development",
      description:
        "Our team specializes in creating bespoke web solutions aligned with your business objectives.",
      color: "#222842",
    },
    {
      img: AppDev,
      title: "Responsive Design",
      description:
        "We ensure seamless performance across desktops, tablets, and mobile devices for an exceptional user experience.",
      color: "#0FC0CF",
    },
    {
      img: DeskTopApp,
      title: "E-Commerce Development",
      description:
        "We create user-friendly e-commerce platforms that foster smooth transactions and customer loyalty.",
      color: "#1CC191",
    },
    {
      img: UXDes,
      title: "Hosting and Deployment",
      description:
        "We guide you through selecting the right hosting environment and ensure a seamless deployment.",
      color: "#512888",
    },
    {
      img: GraphicBrand,
      title: "Consultation and Strategy",
      description:
        "Our experts provide personalized consultation and strategic planning aligned with your business goals.",
      color: "#EF612B",
    },
    {
      img: BrandingServ,
      title: "Branding Guides",
      description:
        "We work closely with you to develop a branding identity that aligns with your standards and preferences.",
      color: "#425AC1",
    },
  ];

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="Services"
    >
      <div className="Services-Wrapper">
        <div className="Services-Content-Heading">
          <FormTitle className="sign-in-form__title" text="Services" />
          <p className="services-p">
            At Beautiful Responsive Web, we offer a comprehensive suite of web
            development services tailored to your needs.
          </p>
        </div>
        <div className="Services-Top-Content-Wrapper">
          {servicesData.map((service, index) => (
            <motion.div
              key={index}
              className="Services-Contents"
              style={{ backgroundColor: service.color }}
              whileHover={{ scale: 1.05 }}
            >
              <div className="Services-Top-Img">
                <div className="Services-Top-Img-Wrapper">
                  <img src={service.img} alt={service.title} />
                </div>
              </div>
              <div className="Services-Bottom">
                <h2>{service.title}</h2>
                <p>{service.description}</p>
                <motion.button className="Services-Button">
                  <Link to="/contact">Get Started</Link>
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
        <WhyChooseUs />
        <Review />
      </div>
    </motion.div>
  );
};

export default Services;
