import { motion } from "framer-motion";
import React from "react";
import ProjectCard from "../ProjectCards/ProjectCard";
import { HomeProjectData } from "../ProjectCards/ProjectData";
import "./Projects.css";

const Projects = () => {
  return (
    <section className="Projects">
      <motion.div
        className="Projects-Wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.h1
          className="Projects-Title"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          Our Past Projects
        </motion.h1>
        <p className="Projects-Description">
          Explore our work and see how we help businesses achieve digital goals.
        </p>
        <div className="Projects-Grid">
          {HomeProjectData.map((item, index) => (
            <motion.div
              key={index}
              className="Project-Card"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
            >
              <ProjectCard data={item} />
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Projects;
