import { motion } from "framer-motion";
import React from "react";
import Form from "../../component/ContactForm/Form";
import ContactLinks from "../../component/ContactLinks/ContactLinks";
import { FormTitle } from "../../component/FormTitle/FomTitle";
import "./Contact.css";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { type: "spring", delay: 0.3 },
  },
  exit: {
    opacity: 0,
    x: "-100vw",
    transition: { ease: "easeInOut" },
  },
};

const Contact = () => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="Contact"
    >
      <div className="Contact-Wrapper">
        <motion.div className="Contact-Header">
          <FormTitle text="Get in Touch With Us" />
        </motion.div>
        <motion.p
          className="contact-parag"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          Have a question? Fill in the form, and our team will get back to you
          shortly.
        </motion.p>
        <div className="Contact-Form-Wrapper">
          <motion.div
            className="Contact-Form"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 100 }}
          >
            <div className="FormContact">
              <Form />
            </div>
          </motion.div>
        </div>
        <ContactLinks />
      </div>
    </motion.div>
  );
};

export default Contact;
