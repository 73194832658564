import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import HeaderNavbar from "./component/HeaderNavbar/HeaderNavbar";
import Home from "./Pages/Home/Home";
import Services from "./Pages/Services/Services";
import Projects from "./Pages/Projects/Projects";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Footer from "./component/Footer/Footer";
import WebDevelopment from "./Pages/WebDevelopment/WebDevelopment";
import Seo from "./Pages/Seo/Seo";
import NewsLetter from "./Pages/NewLetter/NewsLetter";
import WebDesign from "./Pages/WebDesign/WebDesign";
import LogoDesign from "./Pages/LogoDesign/LogoDesign";
import BusinessCardDesign from "./Pages/BusinessCard/BusinessCardDesign";
import BusinessKitDesign from "./Pages/BusinessKitDesign/BusinessKitDesign";
import StartUpPackage from "./Pages/StartUpPackage/StartUpPackage";
import './App.css'
function App() {
  const location = useLocation();

  return (
    <div className="app">
      <HeaderNavbar />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.key}>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/work" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/seo" element={<Seo />} />
          <Route path="/logodesign" element={<LogoDesign />} />
          <Route path="/businesscarddesign" element={<BusinessCardDesign />} />
          <Route path="/businesskit" element={<BusinessKitDesign />} />
          <Route path="/startuppackage" element={<StartUpPackage />} />
          <Route path="/newsletter" element={<NewsLetter />} />
          <Route path="/services/webdesign" element={<WebDesign />} />
          <Route path="/services/webdevelopment" element={<WebDevelopment />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
