import React from "react";
import "./Design.css";
import FirstDesign from "../../images/1st desing logo.png";
import SecondDesign from "../../images/2nddesignlogo.png";
import ThirdDesign from "../../images/3rdDesign.png";
import ForthDesign from "../../images/4designkaneli.png";
import FifthDesign from "../../images/5designKc.png";
import { motion } from "framer-motion";

const logoVariants = {
  hover: {
    scale: 1.1,
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
    transition: { duration: 0.3 },
  },
  tap: {
    scale: 0.95,
    transition: { duration: 0.2 },
  },
};

const Designs = () => {
  const logos = [
    FirstDesign,
    SecondDesign,
    ThirdDesign,
    ForthDesign,
    FifthDesign,
  ];

  return (
    <motion.div
      className="Designs"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="Design-Wrapper">
        <h1>Our Logo Designs</h1>
        <p>
          Explore some of the creative logo designs we have crafted for our
          clients.
        </p>
        <div className="Design-Grid">
          {logos.map((design, index) => (
            <motion.div
              key={index}
              className="Logo-Design"
              variants={logoVariants}
              whileHover="hover"
              whileTap="tap"
            >
              <img src={design} alt={`Design ${index + 1}`} />
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default Designs;
