import React from "react";
import { motion } from "framer-motion";
import { FaSearch, FaDrawPolygon, FaCubes, FaCode } from "react-icons/fa";
import "./HowItWorks.css";

const processSteps = [
  {
    icon: <FaSearch size={40} color="#4A90E2" />,
    title: "Research & Analytics",
    description: "Understand, study & predict consumer behavior before coding.",
  },
  {
    icon: <FaDrawPolygon size={40} color="#7B61FF" />,
    title: "Prototyping Design",
    description:
      "Create a sample product for client interaction before finalization.",
  },
  {
    icon: <FaCubes size={40} color="#FF8A00" />,
    title: "Pixel Perfect UI Design",
    description:
      "Design a user interface that aligns perfectly with client needs.",
  },
  {
    icon: <FaCode size={40} color="#FF5A5F" />,
    title: "Development (Coding)",
    description: "Implement all processes through coding and deployment.",
  },
];

const HowItWorks = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="HowItWorks"
    >
      <div className="HowItWorks-Wrapper">
        <motion.h1
          className="HowItWorks-Title"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          That’s How We Do It
        </motion.h1>
        <div className="HowItWorks-Content">
          {processSteps.map((step, index) => (
            <motion.div
              key={index}
              className="HowItWorks-Process"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
            >
              <div className="HowItWorks-Icon">{step.icon}</div>
              <div className="HowItWorks-Process-Content">
                <h4>{step.title}</h4>
                <p>{step.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default HowItWorks;
