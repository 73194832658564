import React from "react";
import { motion } from "framer-motion";
import ProjectCard from "../../component/ProjectCards/ProjectCard";
import { ProjectData } from "../../component/ProjectCards/ProjectData";
import "./Projects.css";

const Projects = () => {
  return (
    <motion.section
      className="Projects"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="Projects-Wrapper">
        <motion.div
          className="Projects-Heading"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <h1>Our Projects</h1>
          <p className="Project-paragraph">
            We design, develop, and maintain scalable, compatible technologies
            across multiple platforms and devices.
          </p>
        </motion.div>
        <div className="Projects-Container">
          {ProjectData.map((item, index) => (
            <motion.div
              key={item.id}
              className="ProjectCardWrapper"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
            >
              <ProjectCard data={item} />
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Projects;
