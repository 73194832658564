import React, { useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import "./Form.css";

const buttonVariants = {
  hover: {
    scale: 1.05,
    boxShadow: "0px 0px 8px rgba(255,255,255,0.6)",
  },
};

const Form = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    phoneNumber: "",
    lastName: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const postMessage = (e) => {
    e.preventDefault();
    axios
      .post("http://localhost:5005/contact", formData)
      .then((res) => console.log("Message Sent", res))
      .catch((err) => console.log(err));
  };

  return (
    <form className="Form" onSubmit={postMessage}>
      <div className="Form-input">
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder="First Name"
          required
        />
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Phone Number"
          required
        />
      </div>
      <div className="Form-input">
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          placeholder="Last Name"
          required
        />
        <input
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          placeholder="Subject"
          required
        />
      </div>
      <div className="Form-textarea">
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Your Message"
          required
        />
      </div>
      <div className="Contact-Button">
        <motion.button
          variants={buttonVariants}
          whileHover="hover"
          type="submit"
          className="submit-Btn"
        >
          Submit
        </motion.button>
      </div>
    </form>
  );
};

export default Form;
