import styled from "styled-components";
import { motion } from "framer-motion";

// Card container with responsive design
export const Card = styled(motion.div)`
  width: 100%;
  max-width: 400px;
  margin: 1rem auto;
  background: linear-gradient(135deg, rgba(239, 97, 48, 0.45), #f9bca9 100%);
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
`;

// Card media section for the video or image
export const CardMedia = styled(motion.div)`
  position: relative;
  border-radius: 20px;
  overflow: hidden;

  video {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: transform 0.4s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

// Featured tag for highlighting specific projects
export const Featured = styled.div`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
  background-color: rgba(52, 52, 68, 0.85);
  border-radius: 13px;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: bold;
`;

// Title for the featured tag
export const FeatureTitle = styled.span`
  font-size: 0.875rem;
  letter-spacing: 1px;
`;

// Card body for content and meta information
export const CardBody = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Title section with action links
export const CardTitle = styled.div`
  font-size: 1.125rem;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  a {
    font-size: 0.875rem;
    color: #5142fc;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover {
      color: #3a33c5;
    }
  }
`;

// Meta information section
export const MetaInfo = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

// Developer avatar section
export const Developer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #5142fc;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

// Tags section for project-related information
export const TagsCa = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 0.5rem;
`;

export const Tags = styled.div`
  background-color: #5142fc;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`;

export const CtaGarp = styled.div``;

export const CtaLink = styled.a`
  background-color: #474757;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  text-decoration: none;

  &:hover {
    background-color: #313132;
  }
`;

export const MetaList = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const MetaListItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  color: #4a4a4a;

  div {
    &:first-child {
      font-weight: bold;
    }
  }
`;
