import React from "react";
import "./Hero.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import herologo from "../../images/Herobglogo.png";
import img from "../../images/União701.png";

const Hero = () => {
  return (
    <div className="Hero">
      <div className="Hero-Wrapper">
        <motion.div
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 50 }}
          className="Hero-Left"
        >
          <h2>Let's Create Something</h2>
          <p>
            Beautiful Responsive Designs created from scratch with no templates.
            All customized to your specification.
          </p>
          <div className="Hero-buttons">
            <Link to="/contact">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="button"
              >
                Get Started
              </motion.button>
            </Link>
            <Link to="/about">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="nonActiveButton"
              >
                Case Studies
              </motion.button>
            </Link>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          className="Hero-Right"
        >
          <img
            src={herologo}
            alt="Hero Background Logo"
            className="Hero-Logo"
          />
          <img src={img} alt="Person Illustration" className="Hero-Person" />
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
