import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FaBars,
  FaTimes,
  FaHome,
  FaServicestack,
  FaInfoCircle,
  FaEnvelope,
  FaBriefcase,
} from "react-icons/fa";
import Logo from "../../images/Logo1.svg";
import LogoText from "../../images/LogoText.png";
import "./HeaderNavbar.css";

const HeaderNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [portfolioOpen, setPortfolioOpen] = useState(false);

  const toggleMenu = () => setMenuOpen((prev) => !prev);
  const togglePortfolio = () => setPortfolioOpen((prev) => !prev);

  const closeMenus = () => {
    setMenuOpen(false);
    setPortfolioOpen(false);
  };

  return (
    <header className="Header">
      <div className="container">
        <nav className="Navbar">
          <Link to="/" className="navbar-logo" onClick={closeMenus}>
            <motion.div
              className="Logo"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <motion.img
                className="img-Logo"
                src={Logo}
                alt="Logo"
                animate={{ rotate: 360 }}
                transition={{ ease: "linear", duration: 4, repeat: Infinity }}
              />
              <img className="text-Logo" src={LogoText} alt="Logo Text" />
            </motion.div>
          </Link>
          <div className={`Navlinks-Wrapper ${menuOpen ? "open" : ""}`}>
            <ul className="Navbar-links">
              {[
                { path: "/", label: "Home", Icon: FaHome },
                { path: "/services", label: "Services", Icon: FaServicestack },
                { path: "/about", label: "About Us", Icon: FaInfoCircle },
                { path: "/contact", label: "Contact", Icon: FaEnvelope },
              ].map(({ path, label, Icon }, index) => (
                <motion.li
                  key={label}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.05 * index }}
                  onClick={closeMenus}
                >
                  <Link to={path}>
                    <Icon /> {label}
                  </Link>
                </motion.li>
              ))}
              <motion.li
                className="dropdown"
                onClick={togglePortfolio}
                onMouseEnter={() => setPortfolioOpen(true)}
                onMouseLeave={() => setPortfolioOpen(false)}
              >
                <FaBriefcase /> Portfolio
                {portfolioOpen && (
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/work" onClick={closeMenus}>
                        Dev Projects
                      </Link>
                    </li>
                    <li>
                      <Link to="/logodesign" onClick={closeMenus}>
                        Logo Design
                      </Link>
                    </li>
                  </ul>
                )}
              </motion.li>
            </ul>
          </div>
          <button className="menu-toggle" onClick={toggleMenu}>
            {menuOpen ? <FaTimes size={25} /> : <FaBars size={25} />}
          </button>
        </nav>
      </div>
    </header>
  );
};

export default HeaderNavbar;
