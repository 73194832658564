import React from "react";
import "./Card.css";
import { motion } from "framer-motion";

const Card = ({ title, src, desc }) => {
  return (
    <motion.div
      className="LogoCard"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="Card-Image">
        <img src={src} alt={title} />
      </div>
      <div className="Card-Content">
        <h3>{title}</h3>
        <p>{desc}</p>
      </div>
    </motion.div>
  );
};

export default Card;
