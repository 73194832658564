import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardBody,
  CardTitle,
  MetaList,
  MetaListItem,
} from "./ProjectCardStyle";
import { motion } from "framer-motion";

const ProjectCard = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      className="Card"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardMedia>
        <video controls alt="Project Video">
          <source src={data?.projectVideo} type="video/mp4" />
        </video>
      </CardMedia>
      <CardBody>
        <CardTitle>
          <h5>{data?.projectTitle}</h5>
          <a href={data?.link} target="_blank" rel="noopener noreferrer">
            View
          </a>
        </CardTitle>
        <MetaList>
          <MetaListItem>
            <div>Client</div>
            <div>{data?.clientName}</div>
          </MetaListItem>
          <MetaListItem>
            <div>Frontend</div>
            <div>{data?.metaList?.frontend}</div>
          </MetaListItem>
          <MetaListItem>
            <div>Backend</div>
            <div>{data?.metaList?.backend}</div>
          </MetaListItem>
        </MetaList>
      </CardBody>
    </motion.div>
  );
};

export default ProjectCard;
