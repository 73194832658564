import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faLaptopCode,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import "./WhyChooseUs.css";

const WhyChooseUs = () => {
  const reasons = [
    {
      title: "Modern Technology",
      description:
        "We build customized websites that are clean, modern, and mobile-responsive.",
      icon: faLaptopCode,
    },
    {
      title: "Huge Digital Experience",
      description:
        "With extensive experience, we deliver innovative web solutions tailored to your needs.",
      icon: faMobileAlt,
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      className="WhyChooseUs"
    >
      <div className="WhyChooseUs-Wrapper">
        <div className="WhyChooseUs-Content-Heading">
          <h1>Why Choose Us</h1>
          <p>
            We blend creativity with technical expertise, delivering innovative
            web solutions tailored to your unique needs.
          </p>
        </div>
        <div className="WhyChooseUs-Main-Wrapper">
          {reasons.map((reason, index) => (
            <motion.div
              key={index}
              className="WhyChooseUs-Main"
              whileHover={{ scale: 1.05 }}
            >
              <FontAwesomeIcon icon={reason.icon} className="WhyUsIcon" />
              <div className="WhyChooseUs-Bottom">
                <h4>{reason.title}</h4>
                <p>{reason.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default WhyChooseUs;
