import { motion } from "framer-motion";
import React from "react";
import "./Review.css";

const reviews = [
  {
    content:
      "I was referred by a friend to check out this company and honestly, I was skeptical at first due to the complexity of my project. After speaking to them, I was reassured by the great communication and transparency. I would definitely come back again.",
    profile: {
      name: "Sasha Katel",
      role: "Client",
      image:
        "https://images.unsplash.com/photo-1554727242-741c14fa561c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80",
    },
  },
  {
    content:
      "I found this company on Instagram and decided to contact them to see if they will be able to assist me in creating a modern website design for my new business. I was very pleased with my experience with them in making the process easy and stress-free. Thank you.",
    profile: {
      name: "Michelle Akadu",
      role: "Client",
      image:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    },
  },
  {
    content:
      "I was in search for a good designer who is also experienced in development when I stumbled upon this company. They made it easy for me to communicate exactly what I wanted and kept me updated every step of the process. Thank you.",
    profile: {
      name: "Usman Yusef",
      role: "Client",
      image:
        "https://images.unsplash.com/photo-1608791952180-79294109d843?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80",
    },
  },
];

const Review = () => {
  return (
    <section className="Review">
      <div className="Review-Wrapper">
        <motion.h1
          className="Review-Title"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          What Our Clients Say
        </motion.h1>
        <div className="Review-Content-Grid">
          {reviews.map((review, index) => (
            <motion.div
              key={index}
              className="Review-Card"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.2, duration: 0.5 }}
              whileHover={{ scale: 1.05 }}
            >
              <p className="Review-Text">“{review.content}”</p>
              <hr />
              <div className="Review-Profile">
                <img
                  src={review.profile.image}
                  alt={review.profile.name}
                  className="Review-Profile-Image"
                />
                <div className="Review-Profile-Details">
                  <h3>{review.profile.name}</h3>
                  <p>{review.profile.role}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Review;
