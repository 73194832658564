import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./ContactLinks.css";

const ContactLinks = () => {
  const links = [
    { icon: faPhone, title: "Call Us", detail: "+1 949-919-4017" },
    {
      icon: faEnvelope,
      title: "Message Us",
      detail: "beautifulresponsiveweb@gmail.com",
    },
    {
      icon: faMapMarkerAlt,
      title: "Visit Us",
      detail: "Orange County, California",
    },
  ];

  return (
    <div className="ContactLinks">
      <div className="ContactLinks-Wrapper">
        <div className="ContactLinks-Wrapper-Content">
          {links.map((link, index) => (
            <div key={index} className="ContactLinks-Content">
              <FontAwesomeIcon icon={link.icon} className="ContactLinks-Icon" />
              <div className="ContactLinks-Info">
                <h4>{link.title}</h4>
                <p>{link.detail}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactLinks;
