import React from "react";
import "./LogoDesign.css";
import { LogoData } from "../../component/Data/Data";
import { motion } from "framer-motion";
import LogoCard from "../../component/card/Card";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.2 },
  },
};

const childVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const LogoDesign = () => {
  return (
    <motion.div
      className="LogoDesign"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="LogoDesign-Header">
        <h1>Our Past Logo Designs</h1>
        <p>
          Browse through some of our past logo designs, crafted to meet diverse
          client needs with creativity and precision.
        </p>
      </div>
      <div className="LogoDesign-Grid">
        {LogoData.map((data) => (
          <motion.div key={data.id} variants={childVariants}>
            <LogoCard
              title={data.title || "Logo Design"}
              src={data.src}
              desc={data.desc || "Creative and unique design for our client."}
            />
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default LogoDesign;
