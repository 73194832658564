import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import WebDevLogo from "../../images/webdev.png";
import AppDev from "../../images/appdev.png";
import DeskDev from "../../images/desktdev.png";
import UxDev from "../../images/uxdes.png";
import GrapDev from "../../images/graphicdes.png";
import BranDev from "../../images/brandguid.png";
import NextbtnImg from "../../images/arrow.png";
import "./WhatWeDo.css";

const WhatWeDo = () => {
  const services = [
    {
      color: "#222842",
      logo: WebDevLogo,
      link: "/webDev",
      title: "Web Development",
    },
    {
      color: "#0FC0CF",
      logo: AppDev,
      link: "/appdev",
      title: "App Development",
    },
    {
      color: "#1CC191",
      logo: DeskDev,
      link: "/desktopapplication",
      title: "Desktop Application",
    },
    { color: "#512888", logo: UxDev, link: "/uxdesign", title: "UI/UX Design" },
    {
      color: "#EF612B",
      logo: GrapDev,
      link: "/graphicdesigns",
      title: "Graphics Designing",
    },
    {
      color: "#425AC1",
      logo: BranDev,
      link: "/branding",
      title: "Branding Guides",
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="WhatWeDo"
    >
      <div className="WhatWeDo-Wrapper">
        <div className="WhatWeDo-Header">
          <h1>We Do Everything</h1>
          <p>
            We specialize in everything from making sure your business has a web
            presence to creating a responsive website or refining your current
            one to be fully mobile-friendly.
          </p>
        </div>
        <div className="WhatWeDo-Grid">
          {services.map((item, index) => (
            <motion.div
              key={index}
              className="WhatWeDo-Item"
              style={{ backgroundColor: item.color }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link to={item.link} className="WhatWeDo-Link">
                <div className="WhatWeDo-Content">
                  <h2>{item.title}</h2>
                  <img className="NextbtnImg" src={NextbtnImg} alt="Next" />
                </div>
                <img
                  className="WhatWeDo-Logo"
                  src={item.logo}
                  alt={`${item.title} logo`}
                />
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default WhatWeDo;
