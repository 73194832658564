import { motion } from "framer-motion";
import React from "react";
import Hero from "../../component/Hero/Hero";
import Projects from "../../component/HomeProjects/Projects";
import HowItWorks from "../../component/HowitWorks/HowItWorks";
import Designs from "../../component/LogoDesigns/Designs";
import Review from "../../component/Review/Review";
import WhatWeDo from "../../component/WhatWeDo/WhatWeDo";
import "./Home.css";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 50,
      damping: 20,
      staggerChildren: 0.2,
    },
  },
  exit: {
    opacity: 0,
    y: -50,
    transition: { ease: "easeInOut", duration: 0.3 },
  },
};

const sectionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 40, damping: 20 },
  },
};

function Home() {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="Home"
    >
      <motion.section variants={sectionVariants}>
        <Hero />
      </motion.section>
      <motion.section variants={sectionVariants}>
        <Designs />
      </motion.section>
      <motion.section variants={sectionVariants}>
        <WhatWeDo />
      </motion.section>
      <motion.section variants={sectionVariants}>
        <Review />
      </motion.section>
      <motion.section variants={sectionVariants}>
        <Projects />
      </motion.section>
      <motion.section variants={sectionVariants}>
        <HowItWorks />
      </motion.section>
    </motion.div>
  );
}

export default Home;
