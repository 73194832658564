import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaLinkedin,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  const getCurrentYear = () => new Date().getFullYear();

  return (
    <motion.footer
      className="Footer"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="Footer-Wrapper">
        <h1>Bringing Your Ideas to Life</h1>
        <p>Discover how we can assist you.</p>

        <div className="Footer-Buttons">
          <Link to="/contact">
            <motion.button className="FooterActBtn" whileHover={{ scale: 1.1 }}>
              Contact Us
            </motion.button>
          </Link>
          <Link to="/about">
            <motion.button
              className="FooterNonActBtn"
              whileHover={{ scale: 1.1 }}
            >
              About Us
            </motion.button>
          </Link>
        </div>

        <div className="Footer-Content-Wrapper">
          <div className="Footer-Section">
            <h3>Contact Us</h3>
            <p>
              <FaPhoneAlt /> +1 949-919-4017
            </p>
            <p>
              <FaEnvelope /> beautifulresponsiveweb@gmail.com
            </p>
            <p>
              <FaMapMarkerAlt /> Orange County, California
            </p>
          </div>

          <div className="Footer-Section">
            <h3>Company</h3>
            <Link to="/about">About Us</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/contact">Contact</Link>
          </div>

          <div className="Footer-Section">
            <h3>Resources</h3>
            <Link to="/projects">Projects</Link>
            <Link to="/features">Add-On Features</Link>
            <Link to="/newsletter">Blog</Link>
          </div>

          <div className="Footer-Section">
            <h3>Follow Us</h3>
            <div className="Footer-SocialIcons">
              <motion.a href="https://linkedin.com" whileHover={{ scale: 1.1 }}>
                <FaLinkedin />
              </motion.a>
              <motion.a href="https://facebook.com" whileHover={{ scale: 1.1 }}>
                <FaFacebook />
              </motion.a>
              <motion.a
                href="https://instagram.com"
                whileHover={{ scale: 1.1 }}
              >
                <FaInstagram />
              </motion.a>
              <motion.a href="https://twitter.com" whileHover={{ scale: 1.1 }}>
                <FaTwitter />
              </motion.a>
              <motion.a href="https://youtube.com" whileHover={{ scale: 1.1 }}>
                <FaYoutube />
              </motion.a>
            </div>
          </div>
        </div>

        <div className="Footer-Bottom">
          <p>
            © {getCurrentYear()} Beautiful Responsive Web. All rights reserved.
          </p>
          <p>
            <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
            <Link to="/terms">Terms of Use</Link>
          </p>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
