import React from "react";
import "./About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faBullseye,
  faLightbulb,
  faLaptopCode,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.section
      className="About"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="About-Wrapper">
        <div className="About-Content-Heading">
          <h1>About Us</h1>
          <p>
            Welcome to Beautiful Responsive Web, where innovation meets
            community empowerment. We provide tailored solutions to enhance
            small businesses' online presence and help them grow. Let’s make an
            impact together!
          </p>
        </div>

        <div className="About-Sections">
          {[
            {
              title: "Our History",
              description: "Experienced in scalable technologies.",
              icon: faHistory,
            },
            {
              title: "Our Approach",
              description: "Quality and code integrity come first.",
              icon: faBullseye,
            },
            {
              title: "Our Philosophy",
              description: "Empowering small businesses to thrive online.",
              icon: faLightbulb,
            },
            {
              title: "Our Development Process",
              description: "From idea to implementation.",
              icon: faLaptopCode,
            },
          ].map((section, index) => (
            <motion.div
              key={index}
              className="About-Section"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.3 }}
            >
              <FontAwesomeIcon icon={section.icon} className="About-Icon" />
              <h4>{section.title}</h4>
              <p>{section.description}</p>
            </motion.div>
          ))}
        </div>

        <div className="About-Team">
          <h2>Meet Our Team</h2>
          <div className="Team-Member">
            <h4>Bojurie Rogers-Wright</h4>
            <div className="Member-Details">
              <motion.div className="Member-Avatar" whileHover={{ scale: 1.1 }}>
                <FontAwesomeIcon icon={faLightbulb} className="Avatar-Icon" />
              </motion.div>
              <div>
                <p>UI/UX Designer & Developer</p>
              </div>
            </div>
            <div className="Social-Media">
              {[
                { icon: faLinkedin, link: "https://linkedin.com" },
                { icon: faFacebook, link: "https://facebook.com" },
                { icon: faInstagram, link: "https://instagram.com" },
                { icon: faYoutube, link: "https://youtube.com" },
              ].map((social, idx) => (
                <Link key={idx} to={social.link} target="_blank">
                  <FontAwesomeIcon icon={social.icon} className="Social-Icon" />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default About;
